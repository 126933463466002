import { render, staticRenderFns } from "./platform2.vue?vue&type=template&id=068daac1&scoped=true"
import script from "./platform2.vue?vue&type=script&lang=js"
export * from "./platform2.vue?vue&type=script&lang=js"
import style0 from "./platform2.vue?vue&type=style&index=0&id=068daac1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "068daac1",
  null
  
)

export default component.exports